import Form from "../../Forms/Form";
import {Fragment, memo, useEffect, useRef, useState} from "react";
import {ArrowSwapHorizontal, CloseSquare} from "iconsax-react";
import PaymentModal from "../PaymentModal";
import {Heading, Paragraph} from "../../../styles/textStyles";
import useSellContext from "../../../hooks/useContexts/useSellContext";
import {CartSummaryStyled} from "../../../styles/sellStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {sellReducerActions} from "../../../reducers/sellReducer";
import {
  Button,
  CheckBox,
  CreateBankModal,
  CreateCustomerSubscriptionModal,
  CreateCustomPaymentMethodModal,
  CreateDeliveryRateModal,
  CustomerModal,
  DatePicker,
  Input,
  PreviewOrderCheckoutModal,
  ReceiptModal,
  Select,
  TextArea
} from "../../index";
import {checkoutFormDefaultValues} from "../../../data/defaultFormValues";
import {
  useCreateCustomerMutation,
  useCustomerByIdQuery,
  useCustomerQuery
} from "../../../hooks/useServices/useCustomerServices";
import {CheckOutModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {useCreateOrderMutation} from "../../../hooks/useServices/useOrderServices";
import {percentageAndDiscountTypeOptions} from "../../../data/selectField/product";
import PaymentMethod from "../../Forms/PaymentMethod";
import usePaymentModal from "../../../hooks/useModals/usePaymentModal";
import useCartCalculations from "../../../hooks/useUtils/useCartCalculations";
import {useModal} from "../../../hooks";
import SplitOrPartialPayment from "../../Forms/SplitOrPartialPayment";
import {useBankQuery, useCreateBankMutation} from "../../../hooks/useServices/useBankServices";
import {useFormikContext} from "formik";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";
import {useCreateDeliveryRateMutation, useDeliveryRateQuery} from "../../../hooks/useServices/useDeliveryRateServices";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {
  useCreateCustomPaymentMethodMutation,
  useCustomPaymentMethodQuery
} from "../../../hooks/useServices/useCustomPaymentMethodServices";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import {
  useCreateCustomerSubscriptionMutation,
  useCustomerActiveSubscriptionByIdQuery
} from "../../../hooks/useServices/useCustomerSubscriptionServices";
import {currencyFormatterFn} from "../../../utils/dataTypeFormatter";
import usePaymentMethods from "../../../hooks/useUtils/usePaymentMethods";
import {useBusinessByIdQuery} from "../../../hooks/useServices/useStoreServices";
import {Link, useSearchParams} from "react-router-dom";
import {useDownloadReceiptMutation} from "../../../hooks/useServices/useReportServices";
import useHoldOrders from "../../../hooks/useUtils/useHoldOrders";
import {useDesktopCreateOrderOfflineMutation} from "../../../hooks/useDesktop/useDesktopOfflineMutation";
import {
  clearModifyOrderFromLocalStorage,
  clearSavedItemFromCart,
  getModifyOrderToLocalStorage
} from "../../../utils/sell";
import {isAppStoreFront} from "../../../utils";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import {isStoreMainCurrencyNaira} from "../../../hooks/useUtils/useNavigationMenuList";
import {handleSelectCustomerForProductDiscount} from "../../Sell/Cart";
import CreateOrderModel from "../../../models/CreateOrderModel";
import {useActionPermissionFn} from "../../../hooks/usePermissions/useActionPermission";
import {actionsPermissions} from "../../../data/permissions";
import {useStaffByBusinessIdQuery} from "../../../hooks/useServices/useStaffServices";
import {
  assignStaffToTableFeature,
  downloadReceiptMutationInsteadOfReceiptAfterSales
} from "../../../utils/manageDisplayOfFeatures";
import {globalReducerActions} from "../../../reducers/globalReducer";


const handlePaymentModal = (sellSearchRef, sellCategoryRef) => {
  if (!!sellSearchRef.current) {
    sellSearchRef.current.style.zIndex = 0
  }
  
  if (!!sellCategoryRef.current) {
    sellCategoryRef.current.style.zIndex = 0
  }
}


const CheckOutModal = ({ onClose, closeCart = () => {} }) => {
  const [searchParams] = useSearchParams()
  const formDataRef = useRef(null)
  const submitButtonRef = useRef(null)
  const {nairaCurrencyFormatter, currencyFormatter} = useDataTypeFormatter()
  const {data: userProfile} = useUserProfile()
  const {debounceState: customerDebounceState, selectFieldSearch: setCustomerSearchValue} = useQueryDebounce()
  
  const actionPermission = useActionPermissionFn()
  const {posPaymentMethods} = usePaymentMethods()
  
  const {removeOrderOnHoldById} = useHoldOrders()
  const globalContext = useGlobalContext()
  const {toast, isOffline, navigate, globalState, globalReducer} = globalContext
  
  const {
    reducer,
    cart,
    sellSearchRef,
    sellCategoryRef,
    holdOrderId,
    selectedCustomer: selectedCustomerContextState
  } = useSellContext()
  
  const {data: allBanks} = useBankQuery()
  const businessSettings = useBusinessSettingQuery()
  const {data: business} = useBusinessByIdQuery()
  const {isSuccess, data: allCustomers} = useCustomerQuery(customerDebounceState.searchValue)
  
  
  const [showReceipt, setShowReceipt] = useModal()
  const [showDiscount, setShowDiscount] = useModal()
  const [isHomeDelivery, setIsHomeDelivery] = useModal()
  const [showPreviewOrder, setShowPreviewOrder] = useModal()
  const [showCreateBankModal, setShowCreateBankModal] = useModal()
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useModal()
  const [showCreateDeliveryRateModal, setShowCreateDeliveryRateModal] = useModal()
  const [showCreateCustomPaymentMethodModal, setShowCreateCustomPaymentMethodModal] = useModal()
  const [showCreateCustomerSubscriptionModal, setShowCreateCustomerSubscriptionModal] = useModal()
  
  const [openTransferModal, setOpenTransferModal] = usePaymentModal(sellSearchRef, sellCategoryRef, (businessSettings?.sell_screen === "category_based"))
  
  const [receiptData, setReceiptData] = useState([])
  const [deliveryRate, setDeliveryRate] = useState(0)
  const [customerMessage, setCustomerMessage] = useState("")
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [dataForOvaloopTransfer, setDataForOvaloopTransfer] = useState()
  const [isCustomerRequired, setIsCustomerRequired] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(searchParams.get("pay_mode") || "")
  const [{discountType, discountValue}, setCustomDiscount] = useState({discountType: "", discountValue: 0})
  
  const {} = useCustomerByIdQuery(selectedCustomer?.id)
  const createBankMutation = useCreateBankMutation({successFn: setShowCreateBankModal})
  const createCustomerMutation = useCreateCustomerMutation({successFn: setShowCreateCustomerModal})
  const createDeliveryRateMutation = useCreateDeliveryRateMutation({successFn: setShowCreateDeliveryRateModal})
  const createCustomPaymentMethodMutation = useCreateCustomPaymentMethodMutation({successFn: setShowCreateCustomPaymentMethodModal})
  const createCustomerSubscriptionMutation = useCreateCustomerSubscriptionMutation({successFn: setShowCreateCustomerSubscriptionModal})
  const downloadReceiptMutation = useDownloadReceiptMutation({
    successFn: () => {
    }
  })
  const {data: allStaffs} = useStaffByBusinessIdQuery()
  
  
  const cartCalculations = useCartCalculations(cart, discountType, discountValue, deliveryRate)
  const {
    taxPercentage,
    discountPercentage,
    checkoutDiscount,
    checkoutDiscountPercentage,
    totalTax,
    totalDiscount,
    subTotal,
    totalDelivery,
    overallCalculation,
    overallCalculationValue,
    totalServicesValue,
    manualCalculationForProduct
  } = cartCalculations
  
  const handleOrderSuccess = (res) => {
    reducer({
      type: sellReducerActions.CLEAR_CART
    })
  
    setTimeout(() => {
      clearSavedItemFromCart()
    }, 2000)
  
    setReceiptData(res)
  
    if (isAppStoreFront()) {
      toast.success("Order Created", "order_created")
    
      reducer({
        type: sellReducerActions.CLEAR_CART
      })
    
      if (res[0].pay_mode === "ovaloop_transfer") {
        handleTransferPaymentMethod(res)
        return
      }
    
      onClose()
      closeCart()
      return
    }
  
    if (getModifyOrderToLocalStorage()) {
      navigate(-1)
      clearModifyOrderFromLocalStorage()
      return
    }
  
    if (!!holdOrderId) {
      removeOrderOnHoldById(holdOrderId)
    }
  
    if (res[0].pay_mode === "ovaloop_transfer") {
      handleTransferPaymentMethod(res)
      return
    }
  
    if (res[0].pay_mode === "invoice") {
      downloadReceiptMutation.mutate(res?.[0]?.group_id)
    
      // onClose()
      // return
    }
  
    if (downloadReceiptMutationInsteadOfReceiptAfterSales(userProfile?.[0]?.account)) {
      downloadReceiptMutation.mutate(res?.[0]?.group_id)
      onClose()
      return;
    }
  
    setShowReceipt()
  }
  
  const createOrderMutation = useCreateOrderMutation({ successFn: handleOrderSuccess })
  const createOrderOfflineMutation = useDesktopCreateOrderOfflineMutation({ successFn: handleOrderSuccess })
  
  const handleCreateDeliveryRate = (values) => {
    createDeliveryRateMutation.mutate(values)
  }
  
  const handleCreateCustomerPaymentModal = (values) => {
    createCustomPaymentMethodMutation.mutate(values)
  }
  
  const handleCreateCustomerSubscription = (values) => {
    createCustomerSubscriptionMutation.mutate(values)
  }
  
  
  const handleOpenCreateModal = (type) => {
    handlePaymentModal(sellSearchRef, sellCategoryRef);
    
    type === "bank" ? setShowCreateBankModal()
      : type === "preview_order" ? setShowPreviewOrder()
        : type === "customer" ? setShowCreateCustomerModal()
          : type === "delivery_rate" ? setShowCreateDeliveryRateModal()
            : type === "custom_payment_method" ? setShowCreateCustomPaymentMethodModal()
              : type === "customer_subscription" && setShowCreateCustomerSubscriptionModal()
  }
  
  const handleShowDiscount = () => {
    setCustomDiscount({ discountType: "", discountValue: "" })
    setShowDiscount()
  }
  
  const handleCloseReceipt = () => {
    if (globalState.tableId) {
      globalReducer({
        type: globalReducerActions.CLEAR_TABLE_DATA
      })
    
      navigate("/dashboard/table/")
      return
    }
  
    setShowReceipt()
    onClose()
  }
  
  const handleTransferPaymentMethod = (res) => {
    setOpenTransferModal()
    setDataForOvaloopTransfer(res)
  }
  
  const isAllOrderComplimentary = () => {
    const orders = cart.filter(order => order?.complimentary === true)
    if (orders.length === cart.length) {
      setSelectedPaymentMethod("Complimentary")
      return true
    }
  }
  
  const storeFrontUserDetails = () => {
    if (!isAppStoreFront()) return {}
    return {delivery_address: userProfile?.[0]?.address, delivery_phone_number: userProfile?.[0]?.phone}
  }
  
  const getCustomerDetailsForFormIfPreviouslySelected = () => {
    if (selectedCustomerContextState?.id) {
      return {
        customer: selectedCustomerContextState.id,
        customer_name: selectedCustomerContextState.full_name
      }
    }
  }
  
  const isStoreFrontPayOnDeliveryAllowed = () => {
    return business?.pay_on_delivery
  }
  
  useEffect(() => {
    if (isAppStoreFront() && !isStoreFrontPayOnDeliveryAllowed()) {
      setSelectedPaymentMethod("OVALOOP CHECKOUT")
    }
  }, [business])
  
  const handleCheckout = (values) => {
    handlePaymentModal(sellSearchRef, sellCategoryRef)
  
    if (!showDiscount) values.discount_type = ""
    if (searchParams.get("pay_mode")) values.pay_mode = searchParams.get("pay_mode")
    if (isAppStoreFront() && !isStoreFrontPayOnDeliveryAllowed()) values.pay_mode = "ovaloop_transfer"
  
    try {
      const form_values = {
        ...values,
        overallCalculationValue,
        searchParamType: searchParams.get("type"),
        ...(isAllOrderComplimentary() && {pay_mode: "pos"})
      }
  
      const createOrderObj = new CreateOrderModel(cart, form_values, globalContext, manualCalculationForProduct)
  
      createOrderObj.structureOrderForSubmission({
        business,
        staff: userProfile,
        customer: selectedCustomer,
        checkoutDiscountInAmount: checkoutDiscount
      })
  
      const structuredOrder = createOrderObj.getOrderForSubmission()
      isOffline
        ? createOrderOfflineMutation.mutate(structuredOrder)
        : createOrderMutation.mutate(structuredOrder)
  
    } catch (e) {
      toast.error(e.message, e.message)
    }
  }
  
  const handleDeliveryCheck = (value, name, setField) => {
    if(!value) {
      setDeliveryRate(0)
      setField("delivery_rate", "")
      setField("delivery_rate_name", "")
    }
    
    setIsHomeDelivery()
    setIsCustomerRequired(value)
  }
  
  const CreateCustomer = () => (
    <label className="underline" onClick={() => handleOpenCreateModal("customer")}>Create +</label>
  )
  
  const CreateBank = () => (
    <label className="underline" onClick={() => handleOpenCreateModal("bank")}>Create +</label>
  )
  
  const ShowSelectedPayment = () => {
    const [searchParams] = useSearchParams()
    const { setFieldValue } = useFormikContext()
    
    const handleChangePayment = () => {
      setSelectedPaymentMethod("")
      setFieldValue("pay_mode", "")
    }
    
    return (
      <SpaceBetweenStyled className="show_payment_info">
        <FlexStyled $gap={.5}>
          <Heading color="black">Payment Method:</Heading>
          <Paragraph noTop color="secondary" bold>{selectedPaymentMethod.toUpperCase()}</Paragraph>
        </FlexStyled>
        
        {(!searchParams.get("pay_mode") && !isAppStoreFront()) && (
          <Paragraph color="secondary" bold className="underline" onClick={handleChangePayment}>
            CHANGE
            <ArrowSwapHorizontal size={16} color="black"/>
          </Paragraph>
        )}
      </SpaceBetweenStyled>
    )
  }
  
  const isCustomerFieldRequired = () => {
    if(selectedPaymentMethod === "Partial") {
      return true
    }
    
    if(selectedPaymentMethod === "invoice") {
      return true
    }
    
    if(selectedPaymentMethod === "Credit") {
      return true
    }
    
    if(selectedPaymentMethod === "Subscription") {
      return true
    }
    
    if(selectedPaymentMethod === "Customer Wallet") {
      return true
    }
    
    if(isCustomerRequired) {
      return true
    }
  }
  
  const handleSubmitForm = () => {
    submitButtonRef.current.click()
  }
  
  const onOvaloopTransferComplete = () => {
    if(isAppStoreFront()) {
      onClose()
      closeCart()
      setOpenTransferModal()
      return
    }
    
    setDataForOvaloopTransfer(null)
    setShowReceipt()
    setOpenTransferModal()
  }

  return (
    <Fragment>
      {showReceipt && <ReceiptModal isFirstPrint onClose={handleCloseReceipt} data={receiptData}/>}
      
      {openTransferModal && (
        <PaymentModal
          dynamicAccountBody={{ group_order: dataForOvaloopTransfer[0]?.group_order?.id }}
          verifyAccountQueryParam={{ group_order_id: dataForOvaloopTransfer[0]?.group_order?.id }}
          order={dataForOvaloopTransfer}
          onClose={onOvaloopTransferComplete}
        />
      )}
      
      {showPreviewOrder && (
        <PreviewOrderCheckoutModal
          formData={formDataRef.current.values}
          onClose={setShowPreviewOrder}
          handleCheckout={handleSubmitForm}
          cartCalculations={{ ...cartCalculations, deliveryRate }}
        />
      )}
      
      {showCreateDeliveryRateModal && (
        <CreateDeliveryRateModal
          onClose={setShowCreateDeliveryRateModal}
          handleSubmit={handleCreateDeliveryRate}
          mutation={createDeliveryRateMutation}
        />
      )}
      
      {showCreateCustomerModal && (
        <CustomerModal
          position="fixed"
          onClose={setShowCreateCustomerModal}
          mutation={createCustomerMutation}
          handleSubmit={createCustomerMutation.mutate}
        />
      )}
      
      {showCreateBankModal && (
        <CreateBankModal
          position="fixed"
          onClose={setShowCreateBankModal}
          mutation={createBankMutation}
          handleSubmit={createBankMutation.mutate}
        />
      )}
      
      {showCreateCustomPaymentMethodModal && (
        <CreateCustomPaymentMethodModal
          mutation={createCustomPaymentMethodMutation}
          onClose={setShowCreateCustomPaymentMethodModal}
          handleSubmit={handleCreateCustomerPaymentModal}
        />
      )}
      
      {showCreateCustomerSubscriptionModal && (
        <CreateCustomerSubscriptionModal
          customer={selectedCustomer}
          handleSubmit={handleCreateCustomerSubscription}
          onClose={setShowCreateCustomerSubscriptionModal}
          mutation={createCustomerSubscriptionMutation}
        />
      )}
      
      <ModalLayoutStyled $zIndex="99" className="modal" onClick={onClose} $position="absolute" $padding="0" $noScroll>
        <CheckOutModalStyled onClick={e => e.stopPropagation()}>
    
          <CloseSquare size={28} onClick={onClose}/>
          <Heading size={1.3} color="black">Check Out</Heading>
    
          <Form innerRef={formDataRef}
                values={{...checkoutFormDefaultValues, ...storeFrontUserDetails(), ...getCustomerDetailsForFormIfPreviouslySelected()}}
                onSubmit={handleCheckout}>
            {!!selectedPaymentMethod && (
              <ShowSelectedPayment/>
            )}
      
            {((!selectedPaymentMethod && !isAllOrderComplimentary()) && !isAppStoreFront()) && (
              <PaymentMethod
                label="Select Payment Method"
                name="pay_mode"
                methods={posPaymentMethods}
                setPaymentMethod={setSelectedPaymentMethod}
              />
            )}
            
            {/*ONLY SHOW ON STORE FRONT*/}
            {isAppStoreFront() && (
              <Fragment>
                {(isStoreFrontPayOnDeliveryAllowed()) && (
                  <PaymentMethod
                    label="Select Payment Method"
                    name="pay_mode"
                    methods={posPaymentMethods}
                    setPaymentMethod={setSelectedPaymentMethod}
                  />
                )}
                
                {/*<br />*/}
                {!isStoreFrontPayOnDeliveryAllowed() && (
                  <Fragment>
                    <Paragraph color="error" bold>This store does not allow pay on delivery, you will be prompted to make a bank transfer to complete this order.</Paragraph>
                    <br/>
                  </Fragment>
                )}
                {selectedPaymentMethod === "Pay on Delivery" && (
                  <SetHomeDeliveryRate setRate={setDeliveryRate} handleOpenCreateModal={handleOpenCreateModal} />
                )}
  
                <Paragraph color="black" bold>Please confirm both the contact phone number and the delivery address for this order</Paragraph>
                <br/>
                <Input type="number" name="delivery_phone_number" label="Contact Number" />
                <TextArea name="delivery_address" label="Delivery Address" />
                
              </Fragment>
            )}
            
            {/*ONLY SHOW ON MAIN APPLICATION*/}
            <Fragment>
              {(!!selectedPaymentMethod && !isAppStoreFront()) && (
                <Fragment>
                  <CustomPaymentMethod handleOpenCreateModal={handleOpenCreateModal}/>
    
                  {(!!globalState.tableId && assignStaffToTableFeature(userProfile?.[0]?.account)) && (
                    <Select
                      optional
                      name="table_staff_id"
                      valueKey="id"
                      label="Select Staff"
                      displayKey="full_name"
                      placeholder="Select staff for table"
                      options={allStaffs?.results}
                    />
                  )}
    
                  {!getModifyOrderToLocalStorage() && (
                    <Select
                      clearFilter
                      optional={!isCustomerFieldRequired()}
                      options={isSuccess ? allCustomers.results : []}
                      displayKey="full_name"
                      valueKey="id"
                      name="customer"
                      label="Customer"
                      placeholder="Select customer"
                      displayName="customer_name"
                      SideInfo={!isOffline && CreateCustomer}
                      updateFn={(customer) => {
                        handleSelectCustomerForProductDiscount({
                          cart, customer, reducer, currencyFormatter, setCustomerMessage
                        })
                        setSelectedCustomer(customer)
                      }}
                      error={customerMessage}
                      updateQueryResult={setCustomerSearchValue}
                    />
                  )}
  
                  {!!selectedCustomer && !!selectedCustomer?.debt_amount && selectedCustomer?.debt_amount > 0 && (
                    <Paragraph color="error" bold style={{marginBottom: ".6rem"}}>
                      This customer has a debt of {currencyFormatter(selectedCustomer?.debt_amount)}.
                      <Link target="_blank" to={`/dashboard/inventory/debtor/${selectedCustomer.id}`}> Click here to
                        repay
                        now</Link>
                    </Paragraph>
                  )}
  
                  {(selectedPaymentMethod === "Customer Wallet" && selectedCustomer.first_name) && (
                    <Paragraph
                      bold
                      color={
                        isStoreMainCurrencyNaira(business) ? (Number(selectedCustomer?.account_balance) > Number(overallCalculationValue) ? "success" : "error") :
                          (Number(selectedCustomer?.account_balance) > (Number(overallCalculationValue) * Number(businessSettings.currency_exchange_rate)) ? "success" : "error")}
                      noTop>
                      Customer wallet balance: {
                      nairaCurrencyFormatter(selectedCustomer?.account_balance || 0)} {Number(selectedCustomer?.account_balance) < (isStoreMainCurrencyNaira(business) ? Number(overallCalculationValue) : ((Number(overallCalculationValue) * Number(businessSettings.currency_exchange_rate)))) && "(Insufficient balance)"}
                    </Paragraph>
                  )}
    
                  {!!businessSettings?.do_delivery && (
                    <CheckBox afterCheck={handleDeliveryCheck} optional name="delivery" labelProp="Home Delivery"/>
                  )}
    
                  {isHomeDelivery && (
                    <SetHomeDeliveryRate setRate={setDeliveryRate} handleOpenCreateModal={handleOpenCreateModal}/>
                  )}
    
                  {(selectedPaymentMethod === "Split" || selectedPaymentMethod === "Partial") && (
                    <Fragment>
                      <SplitOrPartialPayment totalPayable={overallCalculationValue}/> <br/>
                    </Fragment>
                  )}
                  
                  {(["Bank Transfer", "Pos"].includes(selectedPaymentMethod)) && (
                    <Select
                      label="Select bank"
                      name="banks"
                      options={allBanks}
                      displayKey="bankInfo"
                      valueKey="id"
                      displayName="display_bank"
                      placeholder="Select bank to transfer"
                      SideInfo={!isOffline && CreateBank}
                    />
                  )}
  
                  {(!!businessSettings?.date_created && actionPermission.check(actionsPermissions.customOrderDate)) && (
                    <DatePicker labelBg="white" optional name="date_created" label="Date Created" required
                                placeholder="Select date"/>
                  )}
  
                  {!!businessSettings?.payment_date && (
                    <DatePicker labelBg="white" optional name="payment_date" label="Payment Date" required
                                placeholder="Select date"/>
                  )}
  
                  {/*{!!businessSettings?.due_date && (*/}
                  {/*  <DatePicker onChange={handleDueDate} labelBg="white" optional name="order_due_date"*/}
                  {/*              label="Order Ready By" required placeholder="Select date"/>*/}
                  {/*)}*/}
  
                  {selectedPaymentMethod === "Cash" && (
                    <ConfirmCashChange totalAmount={overallCalculationValue}/>
                  )}
  
                  <CustomerSubscription handleOpenCreateModal={handleOpenCreateModal}/>
  
                  <CheckBox afterCheck={handleShowDiscount} checked={showDiscount} optional name="show_discount"
                            labelProp="Add discount to order"/>
  
                  {showDiscount && (
                    <CustomDiscount setCustomDiscount={setCustomDiscount} />
                  )}
                  
                  <TextArea name="note" optional placeholder="Enter order note" label="Order Note" />
                  
                  <br/><br/>
                </Fragment>
              )}
  
              {/* ----- DO NOT TOUCH ----- This button is hidden, triggered by the ref from cart summary*/}
              <Button ref={submitButtonRef} isLoading={createOrderMutation.isLoading} type="submit" id="submit_checkout" text="Submit" />
            </Fragment>
            
          </Form>
          
          <CartSummaryStyled id="cart_summary">
            <SpaceBetweenStyled>
              <Heading color="secondary">Subtotal:</Heading>
              <Heading color="secondary">{subTotal}</Heading>
            </SpaceBetweenStyled>
  
            {!!totalServicesValue && (
              <SpaceBetweenStyled>
                <Heading color="secondary">Services:</Heading>
                <Heading color="secondary">{currencyFormatter(totalServicesValue)}</Heading>
              </SpaceBetweenStyled>
            )}
  
            {!!deliveryRate && (
              <SpaceBetweenStyled>
                <Heading color="secondary">Delivery Rate:</Heading>
                <Heading color="secondary">+{totalDelivery}</Heading>
              </SpaceBetweenStyled>
            )}
  
            {!!discountPercentage && (
              <SpaceBetweenStyled>
                <Heading color="secondary">Discount:</Heading>
                <Heading color="secondary">-{totalDiscount}</Heading>
              </SpaceBetweenStyled>
            )}
  
            {!!taxPercentage && (
              <SpaceBetweenStyled>
                <Heading color="secondary">Tax</Heading>
                <Heading color="secondary">{totalTax}</Heading>
              </SpaceBetweenStyled>
            )}
  
            {!!checkoutDiscount && (
              <SpaceBetweenStyled>
                <Heading color="secondary">
                  Checkout Discount{!!checkoutDiscountPercentage && ` (${checkoutDiscountPercentage}%)`}:
                </Heading>
                <Heading color="secondary">{currencyFormatter(checkoutDiscount)}</Heading>
              </SpaceBetweenStyled>
            )}
  
            <SpaceBetweenStyled>
              <Heading color="black" bold size={1.1}>Total:</Heading>
              <Heading color="black" bold size={1.1}>
                {overallCalculation}
              </Heading>
            </SpaceBetweenStyled>
  
            {businessSettings.exchange_rate && (
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Exchange Value: </Paragraph>
                <Paragraph color="black" bold>
                  {currencyFormatterFn("en-NG", businessSettings?.ex_currency)(Number(overallCalculationValue) * Number(businessSettings.currency_exchange_rate)) }
                </Paragraph>
              </SpaceBetweenStyled>
            )}
            
            <SpaceBetweenStyled className="checkout_form_actions">
              <Button disabled={!selectedPaymentMethod} isLoading={createOrderMutation.isLoading} onClick={handleSubmitForm} text={!selectedPaymentMethod ? "Proceed" : "Submit"} />
              <Button disabled={!selectedPaymentMethod} onClick={handleOpenCreateModal.bind(this, "preview_order")} text="Preview" />
            </SpaceBetweenStyled>
          
          </CartSummaryStyled>
        </CheckOutModalStyled>
      </ModalLayoutStyled>
    </Fragment>
  )
}

export default CheckOutModal


export const CustomDiscount = memo(({ setCustomDiscount, noWarningMessage }) => {
  const { values, setFieldValue } = useFormikContext()
  
  const handleTypeChange = (data) => {
    setCustomDiscount(prevState => ({...prevState, discountType: data.value}))
  }
  const handleAmountChange = (data) => {
    setCustomDiscount(prevState => ({ ...prevState, discountValue: data || 0 }))
  }
  
  useEffect(() => {
    if(values["show_discount"] && !noWarningMessage) {
      setFieldValue("discount_type", "")
      setFieldValue("discount_type_name", "")
      setFieldValue("discount_value_name", "")
      setFieldValue("discount_value", "")
      setFieldValue("discount_value_format", "")
    }
  }, [values["show_discount"], noWarningMessage])
  
  
  return (
    <Fragment>
      {!noWarningMessage && <Paragraph color="error">This will override existing discount</Paragraph>}
      <Select
        updateFn={handleTypeChange}
        options={percentageAndDiscountTypeOptions}
        displayKey="name"
        valueKey="value"
        label="Discount Type"
        placeholder="Enter discount type"
        name="discount_type"
        displayName="discount_type_name"
      />
  
      <Input
        onChange={handleAmountChange}
        type="number"
        formatNumber
        isCurrency={values.discount_type === "amount"}
        label={`Discount ${values.discount_type_name}`}
        placeholder="Enter value"
        name="discount_value"
        displayName="discount_value_name"
      />
    </Fragment>
  )
})


const SetHomeDeliveryRate = memo(({ handleOpenCreateModal, setRate }) => {
  const { data: allDeliveryRates } = useDeliveryRateQuery()
  const { currencyFormatter, nairaCurrencyFormatter } = useDataTypeFormatter()
  
  const formatDeliveryRate = () => {
    const currFormatter = isAppStoreFront() ? nairaCurrencyFormatter : currencyFormatter
    
    return allDeliveryRates?.results?.map(rate => {
      return { ...rate, rate_details: `${rate.name} ${currFormatter(rate.amount)}` }
    })
  }
  
  const handleSetRate = (data) => {
    setRate(data.amount)
  }
  
  const CreateDeliveryRate = () => (
    <label className="underline" onClick={() => handleOpenCreateModal("delivery_rate")}>Create +</label>
  )
  
  return (
    <Fragment>
      {allDeliveryRates?.results?.length > 0 && (
        <Select
          updateFn={handleSetRate}
          options={formatDeliveryRate()}
          displayKey="rate_details"
          valueKey="id"
          name="delivery_rate"
          label="Delivery Rate"
          placeholder="Select delivery rate"
          displayName="delivery_rate_name"
          SideInfo={!isAppStoreFront() && CreateDeliveryRate}
        />
      )}
    </Fragment>
  )
})


const CustomPaymentMethod = memo(({ handleOpenCreateModal }) => {
  const { values } = useFormikContext()
  const { data: customPaymentMethod } = useCustomPaymentMethodQuery()
  
  const CreatePaymentMethod = () => (
    <label className="underline" onClick={() => handleOpenCreateModal("custom_payment_method")}>Create +</label>
  )
  
  return (
    <Fragment>
      {values.pay_mode === "others" && (
        <Select
          options={customPaymentMethod?.results}
          displayKey="payment_method"
          valueKey="id"
          name="other_payment"
          label="Payment Method"
          placeholder="Select custom payment method"
          displayName="other_payment_name"
          SideInfo={CreatePaymentMethod}
        />
      )}
    </Fragment>
  )
})


const CustomerSubscription = memo(() => {
  const { values } = useFormikContext()
  const { data: allCustomerSubscriptions } = useCustomerActiveSubscriptionByIdQuery(values.customer)
  
  return (
    <Fragment>
      {values.pay_mode === "subscription" && (
        <Fragment>
          <Select
            options={allCustomerSubscriptions?.results}
            displayKey="subscription_service_name"
            valueKey="id"
            label="Subscription package"
            placeholder="Select subscription package"
            name="subscription"
            displayName="subscription_service_name"
          />
        </Fragment>
      )}
    </Fragment>
  )
})


const ConfirmCashChange = ({ totalAmount }) => {
  const { currencyFormatter } = useDataTypeFormatter()
  const [customerChange, setCustomerChange] = useState("")
  const handleCalculateChange = (cash) => {
    setCustomerChange(`Customer change is ${currencyFormatter(Math.abs(Number(totalAmount) - Number(cash)))}`)
  }
  
  return (
    <Fragment>
      <Input
        optional
        error={customerChange}
        onChange={handleCalculateChange}
        name="cash_amount"
        placeholder="Input amount collected"
        label="Calculate customer change" type="number"
        formatNumber
      />
    </Fragment>
  )
}